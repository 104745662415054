"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeComments = exports.Comments = void 0;
/**
 * Comments Manager
 */
class Comments {
    /**
     * Initialize the comments functionality.
     */
    static initializeComments() {
        this.initFancyCancelReply();
        this.initCancelReplyOnClick();
        this.initLikeComment();
        this.initTextareaHeightHandler();
    }
    /**
     * Initialize fancy cancel reply functionality.
     */
    static initFancyCancelReply() {
        const replyLinks = document.querySelectorAll('.comment-reply-link');
        replyLinks.forEach((replyLink) => {
            replyLink.addEventListener('click', Comments.applyFancyStyleToCancelLink);
        });
    }
    /**
     * Apply fancy button styles to the cancel reply link.
     */
    static applyFancyStyleToCancelLink() {
        const cancelLink = document.getElementById('cancel-comment-reply-link');
        if (cancelLink) {
            const classList = [
                'c-button',
                'u-float--right',
                'c-button__basic',
                'basic--secondary',
                'c-button--md',
            ];
            cancelLink.classList.add(...classList);
        }
    }
    /**
     * Initialize cancel reply functionality on click.
     */
    static initCancelReplyOnClick() {
        const cancelLink = document.getElementById('cancel-comment-reply-link');
        if (cancelLink) {
            cancelLink.addEventListener('click', Comments.showReplyButtons);
        }
    }
    /**
     * Show reply buttons by removing the hidden class.
     */
    static showReplyButtons() {
        const replyLinks = document.querySelectorAll('.comment-reply-link');
        replyLinks.forEach((replyLink) => {
            replyLink.classList.remove('u-display--none');
        });
    }
    /**
     * Initialize textarea height adjustment handlers.
     */
    static initTextareaHeightHandler() {
        const commentTextarea = document.getElementById('comment');
        if (commentTextarea) {
            commentTextarea.addEventListener('keydown', Comments.adjustTextareaHeight);
            commentTextarea.addEventListener('keyup', Comments.adjustTextareaHeight);
        }
    }
    /**
     * Adjust the height of the textarea dynamically.
     * @param event - Keyboard event triggered on the textarea.
     */
    static adjustTextareaHeight(event) {
        const textarea = event.target;
        if (textarea) {
            const lines = textarea.value.split(/\r\n|\r|\n/).length;
            textarea.rows = lines;
        }
    }
    /**
     * Initialize like comment functionality.
     */
    static initLikeComment() {
        const likeButtons = document.querySelectorAll('.like-button');
        likeButtons.forEach((likeButton) => {
            likeButton.addEventListener('click', Comments.handleLikeButtonClick);
        });
    }
    /**
     * Handle like button click.
     * @param event - The click event on the like button.
     */
    static handleLikeButtonClick(event) {
        const button = event.currentTarget;
        if (!button)
            return;
        const commentId = button.getAttribute('data-commentid');
        const commentCounter = document.getElementById(`comment-likes-${commentId}`);
        if (!commentId || !commentCounter)
            return;
        const formData = new FormData();
        formData.append('action', 'ajaxLikeMethod');
        formData.append('comment_id', commentId);
        formData.append('nonce', window.likeButtonData.nonce);
        const params = new URLSearchParams(formData);
        fetch(window.likeButtonData.ajax_url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: params.toString(),
        })
            .then((response) => response.json())
            .then(() => {
            let likes = parseInt(commentCounter.getAttribute('data-likes') || '0', 10);
            if (button.classList.contains('active')) {
                likes--;
                button.classList.remove('active');
            }
            else {
                likes++;
                button.classList.add('active');
            }
            commentCounter.textContent = likes.toString();
            commentCounter.setAttribute('data-likes', likes.toString());
        })
            .catch((error) => console.error('Error liking comment:', error));
    }
}
exports.Comments = Comments;
/**
 * Initialize the comments manager.
 */
function initializeComments() {
    document.addEventListener('DOMContentLoaded', () => {
        Comments.initializeComments();
    });
}
exports.initializeComments = initializeComments;
