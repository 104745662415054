"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeCollapsibleSearch = void 0;
class CollapsibleSearchForm {
    constructor(triggerButton, searchForm, closeButton, searchInput) {
        this.triggerButton = triggerButton;
        this.searchForm = searchForm;
        this.closeButton = closeButton;
        this.searchInput = searchInput;
        this.lastFocusedElement = null;
        this.firstFocusableElement = null;
        this.lastFocusableElement = null;
        this.isOpen = false;
        // Function to toggle the search form
        this.toggleSearchForm = (e) => {
            if (this.isOpen) {
                this.closeSearchForm();
            }
            else {
                this.openSearchForm();
            }
        };
        // Function to close the search form
        this.closeSearchForm = () => {
            if (!this.isOpen)
                return;
            this.isOpen = false;
            this.handleIsOpen();
            setTimeout(() => {
                var _a;
                this.searchForm.classList.remove('closing'); // Clean up classes
                (_a = this.lastFocusedElement) === null || _a === void 0 ? void 0 : _a.focus(); // Restore focus to the last focused element
            }, 500);
        };
        // Global click listener to detect outside clicks
        this.globalClickListener = (event) => {
            const target = event.target;
            if (this.isOpen && !this.searchForm.contains(target) && !this.triggerButton.contains(target)) {
                this.closeSearchForm();
            }
        };
        // Global keydown listener for Escape and Tab key handling
        this.globalKeydownListener = (event) => {
            if (!this.isOpen)
                return;
            if (event.key === 'Escape') {
                this.closeSearchForm();
            }
            else if (event.key === 'Tab') {
                this.trapTabKey(event);
            }
        };
        const [firstElement, lastElement] = this.getFocusableElements();
        this.firstFocusableElement = firstElement;
        this.lastFocusableElement = lastElement;
        // Initialize event listeners
        if (this.firstFocusableElement && this.lastFocusableElement) {
            this.initializeEvents();
        }
    }
    // Initialize event listeners once
    initializeEvents() {
        // Always keep these listeners active
        document.addEventListener('click', this.globalClickListener);
        document.addEventListener('keydown', this.globalKeydownListener);
        this.triggerButton.addEventListener('click', this.toggleSearchForm); // Toggle form open/close
        this.closeButton.addEventListener('click', this.closeSearchForm);
    }
    // Function to open the search form
    openSearchForm() {
        this.isOpen = true;
        this.handleIsOpen();
        setTimeout(() => {
            this.searchInput.focus(); // Focus the search input after opening the search form
        }, 500);
    }
    handleIsOpen() {
        if (this.isOpen) {
            this.lastFocusedElement = document.activeElement;
            this.searchForm.classList.remove('closing');
            this.searchForm.setAttribute('aria-hidden', 'false');
            this.searchForm.classList.remove('u-visibility--hidden');
            this.triggerButton.setAttribute('aria-expanded', 'true');
            this.searchForm.classList.add('open');
        }
        else {
            this.searchForm.classList.add('u-visibility--hidden');
            this.searchInput.blur();
            this.searchForm.classList.remove('open');
            this.searchForm.setAttribute('aria-hidden', 'true');
            this.triggerButton.setAttribute('aria-expanded', 'false');
            this.searchForm.classList.add('closing');
        }
    }
    // Trap focus inside the search form
    trapTabKey(event) {
        var _a, _b;
        if (event.shiftKey) {
            if (document.activeElement === this.firstFocusableElement) {
                event.preventDefault();
                (_a = this.lastFocusableElement) === null || _a === void 0 ? void 0 : _a.focus(); // Move focus to the last element
            }
        }
        else {
            if (document.activeElement === this.lastFocusableElement) {
                event.preventDefault();
                (_b = this.firstFocusableElement) === null || _b === void 0 ? void 0 : _b.focus(); // Move focus to the first element
            }
        }
    }
    // Get first and last focusable elements
    getFocusableElements() {
        const focusableElements = this.searchForm.querySelectorAll('input, button');
        return focusableElements.length
            ? [focusableElements[0], focusableElements[focusableElements.length - 1]]
            : [null, null];
    }
}
// Function to initialize the collapsible search form
function initializeCollapsibleSearch() {
    document.addEventListener('DOMContentLoaded', () => {
        const collapsibleSearchElements = document.querySelectorAll('.collapsible-search-form');
        collapsibleSearchElements.forEach((collapsibleSearchElement) => {
            const triggerButton = collapsibleSearchElement.querySelector('.collapsible-search-form__trigger-button');
            const searchForm = collapsibleSearchElement.querySelector('.collapsible-search-form__form');
            const closeButton = collapsibleSearchElement.querySelector('.collapsible-search-form__close-button');
            const searchInput = collapsibleSearchElement.querySelector('.collapsible-search-form input');
            if (triggerButton && searchForm && closeButton && searchInput) {
                new CollapsibleSearchForm(triggerButton, searchForm, closeButton, searchInput);
            }
        });
    });
}
exports.initializeCollapsibleSearch = initializeCollapsibleSearch;
